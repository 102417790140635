import logo from './strangerhub_padded_logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <img src={logo} alt="Logo" />
      </div>
    </div>
  );
}

export default App;
